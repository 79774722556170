
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";

const management = namespace("management");
const app = namespace("app");
@Component({
  components: { FileUpload }
})
export default class managementEdit extends Vue {
  emptyToLine = emptyToLine;
  @management.Action queryTmallChangeDiffDetail;
  @management.Action updateTmallChangeCheckResult;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  showDataChangeCheck: boolean = false;
  checkValue: string = "";
  get breadData() {
    return [
      { name: t("v210831.tmall-data-change"), path: "/management/tmall/tmall-data-change" },
      { name: t("v210831.change-details") }
    ];
  }

  get signInfoModifyCode() {
    return this.$route.params.id;
  }
  checkForm: any = {
    auditMsg: "",
    auditResult: true
  };
  handleCheckResultConfirm() {
    this.btnStartLoading();
    this.updateTmallChangeCheckResult({
      ...this.checkForm,
      signInfoModifyCode: this.signInfoModifyCode
    })
      .then(data => {
        this.$message.success(t("v210831.this-change-has"));
        this.showDataChangeCheck = false;
        this.init();
      })
      .finally(() => {
        this.btnStopLoading();
      });
  }
  created() {
    this.init();
  }
  checkFormRules: any = {
    auditResult: { required: true }
  };
  detailData: any = {};
  oldData: any = {};
  newData: any = {};
  init() {
    this.queryTmallChangeDiffDetail({
      signInfoModifyCode: this.signInfoModifyCode
    }).then(data => {
      console.log(data);
      this.detailData = data.data;
      this.oldData = data.data.oldData || {};
      this.newData = data.data.newData || {};
    });
  }
  handleShowCheckModal() {
    this.showDataChangeCheck = true;
  }
  businessTextMap: any = [
    { text: t("v210831.name-of-legal"), valueKey: "legalRepresentative" },
    { text: t("v210831.corporate-id-number"), valueKey: "legalRepresentativeIdcard" },
    { text: t("v210831.mobile-phone-number"), valueKey: "legalRepresentativePhone" },
    { text: t("store.license-name"), valueKey: "k2Name" },
    { text: t("finance.linkman"), valueKey: "contactsName" },
    { text: t("base.contacts-mobile"), valueKey: "contactsPhone" }
  ];
  invoiceTextMap: any = [
    { text: t("setting.company-name"), valueKey: "taxCompanyName" },
    { text: t("v210831.company-properties"), valueKey: "companyPropertyValue" },
    { text: t("service.taxpayer-registration-number"), valueKey: "taxPayerIdnumber" },
    { text: t("v210801.address"), valueKey: "taxAddress" },
    { text: t("v210831.telephone"), valueKey: "taxPhone" },
    { text: t("service.bank-deposit"), valueKey: "taxBank" },
    { text: t("service.bank-account"), valueKey: "taxBankAccount" }
  ];
  receiptTextMap: any = [
    { text: t("v210831.name-of-payee"), valueKey: "payeeName" },
    { text: t("v210831.province-of-payee"), valueKey: "payeeAddress" },
    { text: t("v210831.payee-bank-of"), valueKey: "payeeBank" },
    { text: t("v210831.payee-account-opening"), valueKey: "payeeSubBank" },
    { text: t("v210831.payee-bank-no"), valueKey: "payeeBankNumber" },
    { text: t("v210831.beneficiary-account-no"), valueKey: "payeeAccount" }
  ];
}
